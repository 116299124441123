<template>
  <vs-card actionable class="cardx ob-h400">
    <div>
      <vs-row>
        <vs-col
          vs-type="flex"
          vs-justify="space-between"
          vs-align="center"
          class="flex-column ob-h400"
        >
          <div class="vs-card--media text-center">
            <img v-if="images" :src="images[0].url" class="ob-img" />

            <img v-else :src="defaultImg" class="ob-img" />
          </div>

          <div>
            <h5 class="text-center">
              {{ title }} {{ amount ? `(${amount})` : "" }}
            </h5>
            <p>{{ dawsat }} دوسات</p>
            <span class="ob-card-price ">{{ `${roundedPrice} SAR` }}</span>
          </div>

          <div class="text-center">
            <vs-button
              class="text-center"
              :color="buttonColor()"
              type="filled"
              @click="setMeal()"
            >
              <i class="fas fa-check-circle"></i>
              {{ buttonColor() != "success" ? "أختر الأن" : "تم الإختيار" }}
            </vs-button>
          </div>
        </vs-col>
      </vs-row>
    </div>
  </vs-card>
</template>
<script>
// import { Swiper, SwiperSlide } from "vue-awesome-swiper";
// import "swiper/swiper-bundle.css";

export default {
  components: {
    // Swiper,
    // SwiperSlide,
  },
  props: {
    mealId: {
      type: String,
      required: true,
    },
    amount: {
      type: String,
    },
    title: {
      type: String,
      default: "وجبة",
    },
    englishName: {
      type: String,
    },
    images: {
      type: Array,
    },
    dawsat: {
      type: Number,
      required: false,
    },
    dayIndex: {
      type: String,
    },
    selectedMeal: {
      type: String,
      required: false,
    },
    personIndex: {
      type: Number,
      required: false,
    },
    price: {
      required: false,
    },
    date: {
      type: Date,
    },
    selected: {
      type: Boolean,
    },
  },
  computed: {
    roundedPrice() {
      return Math.round(this.price * 2) / 2 - 0.01;
    },
    defaultImg() {
      if (this.selectedMeal == "dawnSnack")
        return require("@/assets/images/package-2.png");
      if (this.selectedMeal == "breakfast")
        return require("@/assets/images/package-3.png");
      if (this.selectedMeal == "morningSnack")
        return require("@/assets/images/package-4.png");
      if (this.selectedMeal.includes("lunch"))
        return require("@/assets/images/package-1.png");
      if (this.selectedMeal == "dinner")
        return require("@/assets/images/package-5.png");
      if (this.selectedMeal == "nighttimeSnack")
        return require("@/assets/images/package-6.png");
      else return "https://picsum.photos/500/500";
    },
    selectedPersonIndex() {
      return this.$store.state.selectedPersonIndex;
    },
    filteredIngredients() {
      let temp = [];
      if (this.ingredients) temp = this.ingredients.split("\n");
      else return [[], []];
      let odd = [];
      let even = [];
      for (let index = 0; index < temp.length; index++) {
        const element = temp[index];
        temp[index] = element.split(",");
        if (index % 2) {
          odd.push(temp[index]);
        } else {
          even.push(temp[index]);
        }
      }
      return [even, odd];
    },
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  data() {
    return {
      swiperOptions: {
        pagination: {
          el: ".swiper-pagination",
        },
        // Some Swiper option/callback...
      },
    };
  },
  methods: {
    buttonColor() {
      if (this.selected) return "success";
      else return "danger";
    },
    setMeal() {
      this.$emit(
        "updateMeal",
        this.mealId,
        this.selectedMeal,
        this.dayIndex,
        this.price,
        this.englishName
      );
    },

    clearDaysError(dayIndex, personIndex) {
      this.$store.commit("CLEAR_DAYS_ERRORS", {
        dayIndex,
        personIndex,
      });
    },
  },
};
</script>
<style scoped>
.ob-img {
  max-width: 300px;
  max-height: 300px;
  border-radius: 0.3rem;
  width: auto;
  height: 200px;
}
.ob-h400 {
  height: 400px;
}
.ob-card-price {
  font-size: 1.4rem;
  border-width: 0px 0px 4px 0px;
  border-color: #20889f;
  border-style: solid;
  border-radius: 0.3rem;
  padding: 5px;
  background-color: #25b0d0;
  color: #fff;
}
</style>
