<template>
  <vs-card actionable class="cardx">
    <div>
      <vs-row>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="4"
          vs-xs="12"
        >
          <div class="vs-card--media">
            <swiper ref="mySwiper" :options="swiperOptions">
              <swiper-slide v-for="image in images" :key="image.url"
                ><img :src="image.url" class="ob-img"
              /></swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>
        </vs-col>
        <vs-col vs-justify="center" vs-align="center" vs-w="8" vs-xs="12">
          <h3 class="text-center">{{ title }}</h3>
          <vs-row vs-type="flex">
            <vs-col vs-w="6" v-if="filteredIngredients[0]">
              <vs-list>
                <vs-list-item
                  :title="item[0]"
                  :subtitle="item[1] ? item[1] : ''"
                  v-for="(item, index) in filteredIngredients[0]"
                  :key="index"
                ></vs-list-item>
              </vs-list>
            </vs-col>
            <vs-col vs-w="6" v-if="filteredIngredients[1]">
              <vs-list>
                <vs-list-item
                  :title="item[0]"
                  :subtitle="item[1] ? item[1] : ''"
                  v-for="(item, index) in filteredIngredients[1]"
                  :key="index"
                ></vs-list-item>
              </vs-list>
            </vs-col>
          </vs-row>

          <div class="text-center">
            <vs-button
              class="text-center"
              :color="buttonColor(1)"
              type="filled"
              @click="setMeal()"
            >
              <i class="fas fa-check-circle"></i>
              {{ buttonColor() != "success" ? "أختر الأن" : "تم الإختيار" }}
            </vs-button>
          </div>
        </vs-col>
      </vs-row>
    </div>
  </vs-card>
</template>
<script>
export default {
  props: {
    mealId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    englishName: {
      type: String,
    },
    images: {
      type: Array,
    },
    ingredients: {
      type: String,
      required: false,
    },
    dayIndex: {
      type: String,
    },
    selectedMeal: {
      type: String,
      required: false,
    },
    personIndex: {
      type: Number,
      required: false,
    },
    price: {
      required: false,
    },
    date: {
      type: Date,
    },
    selected: {
      type: Boolean,
    },
  },
  computed: {
    defaultImg() {
      if (this.selectedMeal == "dawnSnack")
        return require("@/assets/images/package-2.png");
      if (this.selectedMeal == "breakfast")
        return require("@/assets/images/package-3.png");
      if (this.selectedMeal == "morningSnack")
        return require("@/assets/images/package-4.png");
      if (this.selectedMeal == "lunch")
        return require("@/assets/images/package-1.png");
      if (this.selectedMeal == "dinner")
        return require("@/assets/images/package-5.png");
      if (this.selectedMeal == "nighttimeSnack")
        return require("@/assets/images/package-6.png");
      else return "https://picsum.photos/500/500";
    },
    selectedPersonIndex() {
      return this.$store.state.selectedPersonIndex;
    },
    filteredIngredients() {
      let temp = [];
      if (this.ingredients) temp = this.ingredients.split("\n");
      else return [[], []];
      let odd = [];
      let even = [];
      for (let index = 0; index < temp.length; index++) {
        const element = temp[index];
        temp[index] = element.split(",");
        if (index % 2) {
          odd.push(temp[index]);
        } else {
          even.push(temp[index]);
        }
      }
      return [even, odd];
    },
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  data() {
    return {
      swiperOptions: {
        pagination: {
          el: ".swiper-pagination",
        },
        // Some Swiper option/callback...
      },
    };
  },
  methods: {
    buttonColor() {
      if (this.selected) return "success";
      else return "danger";
    },
    setMeal() {
      this.$emit(
        "updateMeal",
        this.mealId,
        this.selectedMeal,
        this.dayIndex,
        this.price,
        this.englishName
      );
    },
  },
};
</script>
<style scoped>
.ob-img {
  max-width: 300px;
  max-height: 300px;
  margin-right: 20px;
  border-radius: 0.3rem;
}
</style>
