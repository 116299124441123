<template>
  <div class="container">
    <div class="logo-container">
      <a href="https://dawsat.com/">
        <img
          class="img-fluid logo"
          src="https://s3-me-south-1.amazonaws.com/wp-dawsat/wp-content/uploads/2019/12/26123046/enlogotran.png"
          alt="دوسات"
        />
      </a>
    </div>

    <div class="row" style="align-items: center;">
      <div class="col">
        <div class="py-5 text-center">
          <img
            src="/images/banner.jpg"
            alt
            class="img-responsive"
            width="300"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <h2>شكرا لإشتراكك مع دوسات {{ name }}</h2>
        <p>قم بتعديل الوجبات الخاصة بطلب {{ $route.params.id }}</p>
      </div>
    </div>

    <!-- Services section -->

    <section id="what-we-do" class="mb-3">
      <vs-row vs-align="center" vs-justify="center">
        <vs-col
          class="mt-3"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="4"
          vs-xs="12"
        >
          <vs-select
            class="selectExample w-100"
            label="أختر اليوم الذي تريد تعديله*"
            v-model="selectedDayIndex"
          >
            <vs-select-item
              v-for="date in subscriptionsDates"
              :key="date"
              :value="date"
              :text="date"
            />
          </vs-select>
        </vs-col>
      </vs-row>
      <div v-if="selectedDayIndex">
        <div class="container-fluid" v-if="mealMethod == 'full-meal'">
          <vs-row vs-justify="center" vs-align="center">
            <vs-col>
              <menu-card
                v-for="meal in selectedDayFullMeals"
                :key="meal.id"
                :mealId="meal.id"
                :images="meal.fields['Food Image']"
                :title="meal.fields['Arabic Text for Website']"
                :englishName="
                  meal.fields['Dish Name (from Food Items & Costs)'].toString()
                "
                :ingredients="meal.fields['# Dawsat']"
                :dayIndex="selectedDayIndex"
                :price="meal.fields['Minimum Customer Price for Full Meal']"
                :personIndex="0"
                selectedMeal="Lunch_Full_Meal__c"
                :selected="meal.id == selectedDay.Lunch_Full_Meal_Id__c"
                @updateMeal="updateDayMeal"
              ></menu-card>
            </vs-col>
            <vs-popup
              class="holamundo"
              title="إختر وجبة الحلاة التي تريدها"
              :active.sync="popupActive"
            >
              <swiper ref="mySwiper" :options="swiperOptions">
                <swiper-slide
                  v-for="meal in selectedDayDessert"
                  :key="meal.id"
                  class="ob-wm-300"
                >
                  <small-menu-card
                    :mealId="meal.id"
                    :images="meal.fields['Image']"
                    :title="meal.fields['Arabic Dish Name']"
                    :englishName="meal.fields['Dish Name']"
                    :dawsat="meal.fields['Dawsat']"
                    :dayIndex="selectedDayIndex"
                    :price="
                      meal.fields['Minimum Customer Price (at 25% Food cost)']
                    "
                    selectedMeal="Lunch_Dessert__c"
                    :personIndex="0"
                    :selected="meal.id == selectedDay.Lunch_Dessert_Id__c"
                    @updateMeal="updateDayMeal"
                  ></small-menu-card>
                </swiper-slide>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </swiper>
            </vs-popup>
            <vs-button
              :color="dessertButtonColor"
              type="line"
              icon="cake"
              @click="popupActive = true"
            >
              {{
                dessertButtonColor == "success"
                  ? "قم بتعديل وجبة الحلاة"
                  : "أضف تحلاية الى هذه الوجبة"
              }}</vs-button
            >
          </vs-row>
        </div>
        <div class="container-fluid" v-if="mealMethod == 'customized-meal'">
          <div>
            <!-- main meal -->

            <vs-row class="mt-5">
              <vs-col class="text-center">
                <h4>الوجبة الرئيسية*</h4>
              </vs-col>
            </vs-row>
            <vs-row>
              <swiper ref="mySwiper" :options="swiperOptions">
                <swiper-slide
                  v-for="meal in selectedDayMain"
                  :key="meal.id"
                  class="ob-wm-300"
                >
                  <small-menu-card
                    :mealId="meal.id"
                    :images="meal.fields['Image']"
                    :title="meal.fields['Arabic Dish Name']"
                    :englishName="meal.fields['Dish Name']"
                    :dawsat="meal.fields['Dawsat']"
                    :amount="meal.fields['Dish Quantity/Size']"
                    :dayIndex="selectedDayIndex"
                    :price="
                      meal.fields['Minimum Customer Price (at 25% Food cost)']
                    "
                    selectedMeal="Lunch_Main__c"
                    :personIndex="0"
                    :selected="meal.id == selectedDay.Lunch_Main_Id__c"
                    @updateMeal="updateDayMeal"
                  ></small-menu-card>
                </swiper-slide>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </swiper>
            </vs-row>

            <!-- Side dish -->
            <vs-row class="mt-5">
              <vs-col class="text-center">
                <h4>طبق جانبي</h4>
              </vs-col>
            </vs-row>
            <vs-row>
              <swiper ref="mySwiper" :options="swiperOptions">
                <swiper-slide
                  v-for="meal in selectedDaySideDish"
                  :key="meal.id"
                  class="ob-wm-300"
                >
                  <small-menu-card
                    :mealId="meal.id"
                    :images="meal.fields['Image']"
                    :title="meal.fields['Arabic Dish Name']"
                    :englishName="meal.fields['Dish Name']"
                    :dawsat="meal.fields['Dawsat']"
                    :amount="meal.fields['Dish Quantity/Size']"
                    :dayIndex="selectedDayIndex"
                    :price="
                      meal.fields['Minimum Customer Price (at 25% Food cost)']
                    "
                    selectedMeal="Lunch_Side_Dish__c"
                    :personIndex="0"
                    :selected="meal.id == selectedDay.Lunch_Side_Dish_Id__c"
                    @updateMeal="updateDayMeal"
                  ></small-menu-card>
                </swiper-slide>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </swiper>
            </vs-row>

            <!--  Salad -->
            <vs-row class="mt-5">
              <vs-col class="text-center">
                <h4>السلطة</h4>
              </vs-col>
            </vs-row>
            <vs-row>
              <swiper ref="mySwiper" :options="swiperOptions">
                <swiper-slide
                  v-for="meal in selectedDaySalad"
                  :key="meal.id"
                  class="ob-wm-300"
                >
                  <small-menu-card
                    :mealId="meal.id"
                    :images="meal.fields['Image']"
                    :title="meal.fields['Arabic Dish Name']"
                    :englishName="meal.fields['Dish Name']"
                    :dawsat="meal.fields['Dawsat']"
                    :dayIndex="selectedDayIndex"
                    :price="
                      meal.fields['Minimum Customer Price (at 25% Food cost)']
                    "
                    selectedMeal="Lunch_Salad__c"
                    :personIndex="0"
                    :selected="meal.id == selectedDay.Lunch_Salad_Id__c"
                    @updateMeal="updateDayMeal"
                  ></small-menu-card>
                </swiper-slide>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </swiper>
            </vs-row>

            <!-- soup -->
            <vs-row class="mt-5">
              <vs-col class="text-center">
                <h4>الحساء</h4>
              </vs-col>
            </vs-row>
            <vs-row>
              <swiper ref="mySwiper" :options="swiperOptions">
                <swiper-slide
                  v-for="meal in selectedDaySoup"
                  :key="meal.id"
                  class="ob-wm-300"
                >
                  <small-menu-card
                    :mealId="meal.id"
                    :images="meal.fields['Image']"
                    :title="meal.fields['Arabic Dish Name']"
                    :englishName="meal.fields['Dish Name']"
                    :dawsat="meal.fields['Dawsat']"
                    :dayIndex="selectedDayIndex"
                    :price="
                      meal.fields['Minimum Customer Price (at 25% Food cost)']
                    "
                    selectedMeal="Lunch_Soup__c"
                    :personIndex="0"
                    :selected="meal.id == selectedDay.Lunch_Soup_Id__c"
                    @updateMeal="updateDayMeal"
                  ></small-menu-card>
                </swiper-slide>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </swiper>
            </vs-row>

            <!--  Carbohydrate -->
            <vs-row class="mt-5">
              <vs-col class="text-center">
                <h4>الكاربوهايدرات</h4>
              </vs-col>
            </vs-row>
            <vs-row>
              <swiper ref="mySwiper" :options="swiperOptions">
                <swiper-slide
                  v-for="meal in selectedDayCarbohydrate"
                  :key="meal.id"
                  class="ob-wm-300"
                >
                  <small-menu-card
                    :mealId="meal.id"
                    :images="meal.fields['Image']"
                    :title="meal.fields['Arabic Dish Name']"
                    :englishName="meal.fields['Dish Name']"
                    :dawsat="meal.fields['Dawsat']"
                    :dayIndex="selectedDayIndex"
                    :price="
                      meal.fields['Minimum Customer Price (at 25% Food cost)']
                    "
                    selectedMeal="Lunch_Carbohydrate__c"
                    :personIndex="0"
                    :selected="meal.id == selectedDay.Lunch_Carbohydrate_Id__c"
                    @updateMeal="updateDayMeal"
                  ></small-menu-card>
                </swiper-slide>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </swiper>
            </vs-row>

            <!--  desert -->
            <vs-row class="mt-5">
              <vs-col class="text-center">
                <h4>الحلويات</h4>
              </vs-col>
            </vs-row>
            <vs-row>
              <swiper ref="mySwiper" :options="swiperOptions">
                <swiper-slide
                  v-for="meal in selectedDayDessert"
                  :key="meal.id"
                  class="ob-wm-300"
                >
                  <small-menu-card
                    :mealId="meal.id"
                    :images="meal.fields['Image']"
                    :title="meal.fields['Arabic Dish Name']"
                    :englishName="meal.fields['Dish Name']"
                    :dawsat="meal.fields['Dawsat']"
                    :dayIndex="selectedDayIndex"
                    :price="
                      meal.fields['Minimum Customer Price (at 25% Food cost)']
                    "
                    selectedMeal="Lunch_Dessert__c"
                    :personIndex="0"
                    :selected="meal.id == selectedDay.Lunch_Dessert_Id__c"
                    @updateMeal="updateDayMeal"
                  ></small-menu-card>
                </swiper-slide>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </swiper>
            </vs-row>
          </div>
        </div>
      </div>
      <hr />
      <vs-row
        vs-justify="space-around"
        v-if="tottalAmountToPay && mealMethod == 'customized-meal'"
      >
        <vs-col
          class="mt-3 text-right"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="4"
          vs-xs="12"
        >
          <vs-list>
            <vs-list-header title="ملخص الطلب"></vs-list-header>
            <vs-list-item title="تعديل وجبات">
              <span style="float:left;direction:ltr"
                >{{ tottalAmountToPay }} <br />
                <small style="float:left">SAR</small></span
              >
            </vs-list-item>

            <vs-list-header
              :title="`المجموع: ${tottalAmountToPay} SAR`"
              color="primary"
            ></vs-list-header>
          </vs-list>
        </vs-col>
        <vs-col class="mt-3" vs-w="6" vs-xs="12">
          <vs-tabs alignment="fixed" v-if="tottalAmountToPay > 0">
            <vs-tab label="Visa/Mastercard">
              <credit-card @cardTokenized="sendData"></credit-card>
            </vs-tab>
            <!-- <vs-tab label="Apple Pay">
              <div></div>
            </vs-tab> -->
          </vs-tabs>
          <div v-else>
            <h3>
              سوف يتم اعادة {{ tottalAmountToPay * -1 }} SAR الى حسابك عند اتمام
              التعديل
            </h3>
          </div>
        </vs-col>
      </vs-row>
    </section>

    <vs-row>
      <vs-col type="flex" vs-justify="center" vs-align="center">
        <div class="text-center">
          <vs-button
            ref="loadableButton"
            class="vs-con-loading__container text-center"
            type="filled"
            @click="formSubmitted"
            id="button-with-loading"
          >
            <i class="fas fa-check-circle"></i>
            أرسل
          </vs-button>
        </div>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import MenuCard from "@/components/MenuCardUpdate";
import SmallMenuCard from "@/components/SmallMenuCardUpdate";
import CreditCard from "../components/CreditCard";

export default {
  components: {
    MenuCard,
    CreditCard,
    SmallMenuCard,
  },
  computed: {
    dessertButtonColor() {
      let day = this.selectedDay;
      if (day["Lunch_Side_Dish__c"]) {
        return "success";
      } else {
        return "#25b0d0";
      }
    },
    deliveryDays() {
      return this.$store.getters.selectedDeliveryDays;
    },
    popupActive() {
      return this.$store.state.popupActive;
    },
    subscriptionsDates() {
      let dates = [];
      for (let index = 0; index < this.days.length; index++) {
        const day = this.days[index];
        dates.push(day.Date__c);
      }
      return dates;
    },
    selectedDay() {
      return this.days.find(day => {
        if (day.Date__c == this.selectedDayIndex) return true;
        else return false;
      });
    },
    tottalAmountToPay() {
      let tottalPrice = 0;
      this.priceDiffrence.forEach(day => {
        for (const key in day) {
          const mealPrice = day[key];
          tottalPrice += mealPrice;
        }
      });
      const decimalPlaces = 2;

      return Number(
        Math.round(parseFloat(tottalPrice + "e" + decimalPlaces)) +
          "e-" +
          decimalPlaces
      );
    },
    ////////////
    //meals
    selectedDayFullMeals() {
      return this.meals.filter(item => {
        if (
          parseInt(item.fields.Day) ==
            this.subscriptionsDates.indexOf(this.selectedDayIndex) + 1 &&
          item.fields["Meal Name"] == "Lunch Full Meal"
        ) {
          return true;
        } else {
          false;
        }
      });
    },
    selectedDaySoup() {
      return this.meals.filter(item => {
        if (item.fields["Minimum Customer Price (at 25% Food cost)"] == 0)
          return false;
        if (item.fields["Food Type"] == "Soup") {
          return true;
        } else {
          false;
        }
      });
    },
    selectedDaySalad() {
      return this.meals.filter(item => {
        if (item.fields["Minimum Customer Price (at 25% Food cost)"] == 0)
          return false;
        if (item.fields["Food Type"] == "Side Salad") {
          return true;
        } else {
          false;
        }
      });
    },
    selectedDayMain() {
      return this.meals.filter(item => {
        if (item.fields["Minimum Customer Price (at 25% Food cost)"] == 0)
          return false;
        if (item.fields["Food Type"] == "Lunch Main") {
          return true;
        } else {
          false;
        }
      });
    },
    selectedDaySideDish() {
      return this.meals.filter(item => {
        if (item.fields["Minimum Customer Price (at 25% Food cost)"] == 0)
          return false;
        if (item.fields["Food Type"] == "Side Dish") {
          return true;
        } else {
          false;
        }
      });
    },
    selectedDayCarbohydrate() {
      return this.meals.filter(item => {
        if (item.fields["Minimum Customer Price (at 25% Food cost)"] == 0)
          return false;
        if (item.fields["Food Type"] == "Carbohydrate") {
          return true;
        } else {
          false;
        }
      });
    },
    selectedDayDessert() {
      return this.meals.filter(item => {
        if (item.fields["Minimum Customer Price (at 25% Food cost)"] == 0)
          return false;
        if (item.fields["Food Type"] == "Dessert Snack") {
          return true;
        } else {
          false;
        }
      });
    },
  },
  data() {
    return {
      selectedDayIndex: "",
      days: [],
      daysRefrence: [],
      name: "",
      mealMethod: "",
      compleatedCount: 0,
      errorMessage:
        "Contract status is draft please make sure that your payment succeded before accessing this page. if it really succeded and you see this message please contact us throw any contact method provided in our website",
      errorOccured: false,
      meals: [],
      duration: "",
      priceDiffrence: [],
      swiperOptions: {
        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: "auto",
        centerInsufficientSlides: true,
        coverflowEffect: {
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: false,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  mounted() {
    this.$vs.loading();
    this.$http
      .get("/api/v2/kitopi/groups/" + this.$route.params.id)
      .then(response => {
        let kitopiGroup = response.data.groups;
        this.days = response.data.days.slice();
        this.priceDiffrence = Array.apply(null, {
          length: this.days.length,
        }).map(Object, Object);
        this.daysRefrence = JSON.parse(
          JSON.stringify(response.data.days.slice())
        );
        this.name = kitopiGroup.Full_Name__c;
        this.mealMethod = kitopiGroup.Meal_Mthod__c;
        this.duration = kitopiGroup.Duration__c;
        if (this.mealMethod == "full-meal") {
          this.$http
            .get("/api/v2/kitopi/lunch-full-meals")
            .then(response => {
              this.$vs.loading.close();
              this.meals = response.data.records;
            })
            .catch(error => {
              this.errorOccured = true;
              this.$vs.loading.close();
              if (
                !(
                  error.response &&
                  error.response.data &&
                  error.response.data.message
                )
              ) {
                this.errorMessage =
                  "Server error occured please try again later";
              }
              alert(this.errorMessage);
              this.$router.push("/");
            });
        } else if (this.mealMethod == "customized-meal") {
          this.$http
            .get("/api/v2/kitopi/lunch-customized-meals")
            .then(response => {
              this.$vs.loading.close();
              this.meals = response.data.records;
            })
            .catch(error => {
              this.errorOccured = true;
              this.$vs.loading.close();
              if (
                !(
                  error.response &&
                  error.response.data &&
                  error.response.data.message
                )
              ) {
                this.errorMessage =
                  "Server error occured please try again later";
              }
              alert(this.errorMessage);
              this.$router.push("/");
            });
        }

        this.$vs.loading.close();
      })
      .catch(error => {
        this.$vs.loading.close();
        if (
          !(
            error.response &&
            error.response.data &&
            error.response.data.message
          )
        ) {
          this.errorMessage =
            "Contract status is draft please make sure that your payment succeded before accessing this page. if it really succeded and you see this message please contact us throw any contact method provided in our website";
        }
        alert(this.errorMessage);
        this.$router.push("/");
      });
  },
  methods: {
    formSubmitted() {
      if (this.tottalAmountToPay > 0 && this.mealMethod == "customized-meal") {
        window.Frames.submitCard();
      } else {
        this.sendData();
      }
    },
    sendData(token) {
      this.$vs.loading();

      this.$http
        .post(`/api/v2/kitopi/groups/${this.$route.params.id}/meals/update`, {
          days: this.days,
          price: this.tottalAmountToPay,
          token,
          mealMethod: this.mealMethod,
        })
        .then(() => {
          this.$vs.loading.close();
          alert("تم تقديم البيانات بنجاح");
        });
    },
    updateDayMeal(mealId, selectedMeal, dayIndex, price, englishName) {
      let daysIndex = this.days.map(x => x.Date__c).indexOf(dayIndex);
      let oldMeal = this.meals.find(meal => {
        if (meal.id == this.daysRefrence[daysIndex][selectedMeal]) {
          return true;
        } else {
          return false;
        }
      });
      this.days[daysIndex][selectedMeal] = englishName; // set new meal
      let postionToInsertAt = selectedMeal.lastIndexOf("__c");
      var selectedMealId = [
        selectedMeal.slice(0, postionToInsertAt),
        "_Id",
        selectedMeal.slice(postionToInsertAt),
      ].join("");
      this.days[daysIndex][selectedMealId] = mealId; // set new meal
      if (!oldMeal) {
        oldMeal = {};
        oldMeal["fields"] = {};
        oldMeal.fields["Minimum Customer Price for Full Meal"] = 0;
        oldMeal.fields["Minimum Customer Price (at 25% Food cost)"] = 0;
      }
      //find price diffrence
      let newPrice = NaN;
      if (this.mealMethod == "full-meal") {
        newPrice =
          price - oldMeal.fields["Minimum Customer Price for Full Meal"];
      } else if (this.mealMethod == "customized-meal") {
        newPrice =
          price - oldMeal.fields["Minimum Customer Price (at 25% Food cost)"];
      }
      this.priceDiffrence[daysIndex][selectedMeal] = newPrice;
      this.priceDiffrence.push({});
      this.priceDiffrence.pop();
    },
  },
};
</script>

<style>
@font-face {
  font-family: "TheSansArabic-Bold";
  src: url(/fonts/TheSansArabic-Bold.eot);
  src: url(/fonts/TheSansArabic-Bold.eot?#iefix) format("embedded-opentype"),
    url(/fonts/TheSansArabic-Bold.woff2) format("woff2"),
    url(/fonts/TheSansArabic-Bold.woff) format("woff"),
    url(/fonts/TheSansArabic-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "TheSansArabic-SemiBold";
  src: url(/fonts/TheSansArabic-SemiBold.eot);
  src: url(/fonts/TheSansArabic-SemiBold.eot?#iefix) format("embedded-opentype"),
    url(/fonts/TheSansArabic-SemiBold.woff2) format("woff2"),
    url(/fonts/TheSansArabic-SemiBold.woff) format("woff"),
    url(/fonts/TheSansArabic-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "TheSansArabic-ExtraBold";
  src: url(/fonts/TheSansArabic-ExtraBold.eot);
  src: url(/fonts/TheSansArabic-ExtraBold.eot?#iefix)
      format("embedded-opentype"),
    url(/fonts/TheSansArabic-ExtraBold.woff2) format("woff2"),
    url(/fonts/TheSansArabic-ExtraBold.woff) format("woff"),
    url(/fonts/TheSansArabic-ExtraBold.ttf) format("truetype");
}
body {
  background-color: #f4eee2;
  direction: rtl;
  text-align: right;
  font-family: "TheSansArabic-SemiBold" !important;
}
.list-group {
  padding-right: 0;
}

section {
  padding: 60px 0;
}
section .section-title {
  text-align: center;
  color: #6d5544;
  margin-bottom: 50px;
  text-transform: uppercase;
}
#what-we-do {
  background: #ffffff;
}
#what-we-do .card {
  padding: 1rem !important;
  border: none;
  margin-bottom: 1rem;
  -webkit-transition: 0.5s all ease;
  -moz-transition: 0.5s all ease;
  transition: 0.5s all ease;
}
#what-we-do .card:hover {
  -webkit-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
  -moz-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
  box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
}
#what-we-do .card .card-block {
  padding-left: 50px;
  position: relative;
}
#what-we-do .card .card-block a {
  color: #007b5e !important;
  font-weight: 700;
  text-decoration: none;
}
#what-we-do .card .card-block a i {
  display: none;
}
#what-we-do .card:hover .card-block a i {
  display: inline-block;
  font-weight: 700;
}
#what-we-do .card .card-block:before {
  font-family: FontAwesome;
  position: absolute;
  font-size: 39px;
  color: #6d5544;
  left: 0;
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
}
#what-we-do .card .block-1:before {
  content: "\275B";
}
#what-we-do .card .block-2:before {
  content: "\275B";
}
#what-we-do .card .block-3:before {
  content: "\275B";
}

#what-we-do .card:hover .card-block:before {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
  -webkit-transition: 0.5s all ease;
  -moz-transition: 0.5s all ease;
  transition: 0.5s all ease;
}
.logo-container {
  background: #6a5544;
  border-radius: 0.3rem;
  position: absolute;
  height: auto;
  top: 10px;
  z-index: 9999;
}
.logo {
  margin: 20px;
  width: 70px;
}
/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .logo {
    margin: 10px;
    width: 40px;
  }
}
</style>
